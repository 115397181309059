import escapeHtml from '@/helpers/escapeHtml'

function formatMessage(str) {
  if (typeof str !== 'string') {
    return str
  }

  return escapeHtml(str).replace(
    /\*(.*?)\*/g,
    '<i style="opacity: 0.66;">$1</i>',
  )
}

export default formatMessage
