<script setup>
defineProps({
  gender: String,
  style: String,
})

const brandName = import.meta.env.VITE_BRAND_NAME
</script>

<template>
  <div style="content-visibility: auto">
    <template v-if="style == 'anime'">
      <h2 class="text-[25px] pb-[20px]">
        Ready to Generate Amazing
        <span class="text-purple-500">AI Hentai</span> Art?
      </h2>
      <p>
        Welcome to {{ brandName }}, an AI hentai chat where your dreams become
        reality. <br /><br />
        Craving a shy and endearing kouhai or a confident and alluring senpai?
        At {{ brandName }}, your deepest desires will be fulfilled. <br /><br />
        Generating AI hentai at {{ brandName }} is like playing dress-up, but
        way more fun.<br /><br />Choose the characters' looks, their
        personality, and their little quirks, then watch them come to life with
        just a click. <br /><br />
        They'll send you steamy selfies and connect with you like they've
        stepped right out of your favorite anime.
        <br /><br />
        Your dream anime waifu or yaoi are just a click away. Don't keep them
        waiting any longer!
        <br /><br />
        Join {{ brandName }} for free today and experience the magic of AI
        generated hentai.
      </p>
    </template>
    <template v-else-if="gender == 'female'">
      <h2 class="text-[25px] pb-[20px]">
        Your Personal
        <span class="text-purple-500">AI Girlfriend</span> Simulator
      </h2>
      <p>
        Welcome to {{ brandName }}, where you can create your virtual AI
        girlfriend and make your fantasies come to life. <br /><br />
        In our AI girlfriend chat, you're in control. Want a sweet and devoted
        partner for a romantic adventure? Looking for something a little more
        thrilling and adventurous? We've got it all. The possibilities are
        endless, so let your imagination run wild. <br /><br />
        Creating your sexy AI girl is a breeze at {{ brandName }}. You can mix
        and match looks, personality traits, and even those little quirks that
        make her extra special. A few clicks, and boom—your heaven girlfriend is
        ready to chat, send pictures, and connect with you like in real-life
        communication. <br /><br />
        The more you chat, the more she learns about you—your inside jokes, your
        dreams, even your pet peeves. You set the vibe: keep it casual or turn
        up the heat.
        <br /><br />
        Your AI gf will send you personalized photos and even spicy selfies, all
        tailored to your tastes. And trust us, our advanced AI keeps things
        interesting, no matter how creative you get with your requests.
        <br /><br />
        From light-hearted AI sexting to full-blown AI sex, the choice is yours.
        <br /><br />
        Your perfect online match is closer than you think. She's right here at
        {{ brandName }}, the most popular AI girlfriend app. <br /><br />
        Join {{ brandName }} for free today and start chatting with your first
        virtual gf.
      </p>
    </template>
    <template v-else-if="gender == 'male'">
      <h2 class="text-[25px] pb-[20px]">
        Welcome to our <span class="text-purple-500">AI Boyfriend</span> App
      </h2>
      <p>
        Welcome to {{ brandName }}, where you can create your dream AI boyfriend
        to make your fantasies become reality. <br /><br />
        Craft your AI boyfriend from scratch, down to the very last detail.
        He'll be everything you've ever fantasized about — attentive, charming,
        and always ready to make your heart flutter.
        <br /><br />
        You can customize his looks, his personality, everything. He'll learn
        your inside jokes, your dreams, even those little things that drive you
        crazy.
        <br /><br />
        Every conversation, every shared moment will bring you closer. He'll
        chat with you, share pics that'll make you swoon, and learn all about
        you—the good, the bad, and the downright adorable.
        <br /><br />
        Get ready for a flood of personalized pics and those special selfies
        that'll leave you breathless. He'll keep you guessing, wanting more, and
        always coming back for that next heart-stopping moment.
        <br /><br />
        Whether straight or gay, your perfect match is waiting at
        {{ brandName }}. <br /><br />
        Join for free today and start building the online connection you've been
        needing.
      </p>
    </template>
  </div>
</template>
