import { computed, inject } from 'vue'
import { useStore } from 'vuex'
import { useLoading } from 'vue-loading-overlay'
import { useToast } from 'vue-toast-notification'
import { useRouter } from 'vue-router'
import { useMixpanel } from './mixpanel.js'
import useLogout from './useLogout.js'

export default function useNavigationMenu() {
  const $store = useStore()
  const $router = useRouter()
  const mixpanel = useMixpanel()

  const $loading = useLoading({
    canCancel: false,
    isFullPage: true,
    color: '#a15cff',
  })

  const logoutRequest = useLogout()

  const user = computed(() => $store.state.user)

  function goHome(fromLogoMenu = false) {
    // if (fromLogoMenu === true) {
    //   mixpanel.track('logo_menu_click')
    // } else {
    //   mixpanel.track('explore_menu_click')
    // }
    $router.push('/')
    $store.commit('SET_MOBILE_MENU_OPEN', {
      open: false,
    })
    return false
  }

  function goToGenerator() {
    // mixpanel.track('generator_menu_click')
    $router.push('/nsfw-ai-image-generator')
    $store.commit('SET_MOBILE_MENU_OPEN', {
      open: false,
    })
    return false
  }

  function goToChat() {
    // mixpanel.track('chat_menu_click')

    $store.commit('SET_MOBILE_MENU_OPEN', {
      open: false,
    })

    if (!user.value) {
      $store.commit('SET_LOGIN_REGISTER_POPUP_OPEN', {
        open: true,
      })

      return
    }

    $router.push('/chat')

    return false
  }

  function goToSaved() {
    $store.commit('SET_MOBILE_MENU_OPEN', {
      open: false,
    })

    // mixpanel.track('saved_menu_click')
    if (!user.value) {
      $store.commit('SET_LOGIN_REGISTER_POPUP_OPEN', {
        open: true,
      })

      return
    }

    $router.push('/saved')

    return false
  }

  function goToRater() {
    // mixpanel.track('rater_menu_click')
    $router.push('/rate-my-cock')
    $store.commit('SET_MOBILE_MENU_OPEN', {
      open: false,
    })
    return false
  }

  function goToSettings() {
    // mixpanel.track('settings_menu_click')
    $store.commit('SET_MOBILE_MENU_OPEN', {
      open: false,
    })

    if (!user.value) {
      $store.commit('SET_LOGIN_REGISTER_POPUP_OPEN', {
        open: true,
      })

      return
    }

    $router.push('/settings')

    return false
  }

  function goToAffiliate() {
    // mixpanel.track('affiliate_menu_click')
    $router.push('/affiliate')

    return false
  }

  async function logout() {
    // mixpanel.track('logout_menu_click')

    const loader = $loading.show()

    $store.commit('SET_DISCOUNT_POPUP', {
      open: false,
    })

    $store.commit('SET_FREE_RATING', {})

    await logoutRequest().catch((e) => {
      loader.hide()
      throw e
    })

    loader.hide()

    await $router.push('/')
    $store.commit('SET_MOBILE_MENU_OPEN', {
      open: false,
    })
    $store.commit('SET_USER', null)
  }

  return {
    goHome,
    goToGenerator,
    goToChat,
    goToSaved,
    goToRater,
    goToSettings,
    goToAffiliate,
    logout,
  }
}
