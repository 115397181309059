<script setup>
import Popup from './Popup.vue'
import { CheckCircleIcon } from '@heroicons/vue/20/solid'
import { watch, toRefs } from 'vue'
import { useMixpanel } from '@/composables/mixpanel'
import ButtonComponent from './ButtonComponent.vue'
import { useStore } from 'vuex'

const props = defineProps({
  open: Boolean,
  text: String,
})
const { open, text } = toRefs(props)

const emit = defineEmits(['update:open'])

const mixpanel = useMixpanel()

const $store = useStore()

watch(open, (val) => {
  if (val) {
    const freeLimitHit = $store.state.freeLimitHit
    mixpanel.track(
      'open_subscribe_popup',
      { free_limit_hit: freeLimitHit },
      {
        source: 'current',
      },
    )
  }
})
</script>

<template>
  <Popup
    class="sticky z-40"
    popupStyle="bg-[#0A0D22] w-[625px] pt-[50px] pb-5 px-[30px] lg:py-[75px] mx-4 lg:mx-0 lg:px-[122px]"
    :open="open"
    :mobile="false"
    @update:open="emit('update:open', $event)"
  >
    <div class="px-1 py-2 md:px-4 md:py-5 sm:p-6 mt-2">
      <template v-if="text">
        <h2 class="text-xl lg:text-3xl font-bold lg:leading-10">
          Free Limit Reached
        </h2>
        <h3 class="mt-1">Upgrade to Pro in order to {{ text }}</h3>
      </template>
      <template v-else
        ><h2 class="text-xl lg:text-3xl font-bold lg:leading-10">
          Upgrade your Account
        </h2>
        <h3 class="mt-1">This feature is only available to Pro users</h3>
      </template>
      <label for="gender" class="block text-sm font-semibold mt-6 text-white">
        And much more:
      </label>
      <ul
        class="mt-3 font-semibold text-white grid gap-y-3 gap-x-2 grid-cols-[20px_1fr] grid-cols-2 items-center"
      >
        <CheckCircleIcon class="w-full"></CheckCircleIcon>
        <span>Unlimited Messaging</span>
        <CheckCircleIcon class="w-full"></CheckCircleIcon>
        <span>Create up to 25 Girls</span>
        <CheckCircleIcon class="w-full"></CheckCircleIcon>
        <span>Generate up to 100 Uncensored Pics</span>
        <CheckCircleIcon class="w-full"></CheckCircleIcon>
        <span>Generate up to 10 Ratings</span>
      </ul>
      <div
        class="flex justify-between mt-6 lg:mt-10 text-sm font-semibold gap-x-3 text-center"
      >
        <ButtonComponent
          to="/subscribe"
          class="p-2 lg:p-3 w-full"
          @click="$emit('update:open', false)"
          >Upgrade</ButtonComponent
        >
      </div>
    </div>
  </Popup>
</template>
