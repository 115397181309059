export default {
  FORGOT: {
    type: 'Subscriptions', // 'All', 'Subscriptions','Purchases'
    isReminder: true,
    percentage: '25',
    subscriptions: [
      {
        name: 'LunaLust Pro',
        luna: 500,
        amount: '14.95',
        discountPercentage: '25',
        type: 1,
      },
      {
        name: 'LunaLust Pro',
        luna: 500,
        prediscountAmount: '239.40',
        discountPercentage: '75',
        monthlyAmount: '4.95',
        amount: '59.40',
        type: 2,
      },
    ],
    purchases: [
      {
        amount: '9.95',
        luna: 200,
        name: 'Lunar<br>Breeze',
        image: 'packages/1.svg',
      },
      {
        amount: '29.95',
        prediscoutAmount: '39.99',
        luna: 800,
        name: 'Lunar<br>Odyssey',
        image: 'packages/2.svg',
        popular: true,
      },
      {
        amount: '49.95',
        prediscoutAmount: '89.99',
        luna: 1600,
        name: 'Lunar<br>Harmony',
        image: 'packages/3.svg',
      },
      {
        amount: '99.95',
        prediscoutAmount: '179.99',
        luna: 3600,
        name: 'Lunar<br>Eclipse',
        image: 'packages/4.svg',
        bestDeal: true,
      },
    ],
  },
  RECOVER: {
    type: 'Subscriptions', // 'All', 'Subscriptions','Purchases'
    isReminder: true,
    percentage: '50',
    subscriptions: [
      {
        name: 'LunaLust Pro',
        luna: 500,
        amount: '9.95',
        discountPercentage: '50',
        type: 1,
      },
      {
        name: 'LunaLust Pro',
        luna: 500,
        prediscountAmount: '239.40',
        discountPercentage: '75',
        monthlyAmount: '3.95',
        amount: '47.40',
        type: 2,
      },
    ],
  },
}
