// These methods are complicated because they need to support
// emojis and different languages besides English.
// If you just use btoa and atob you get this error:
// DOMException: Failed to execute 'btoa' on 'Window': The string to be encoded contains characters outside of the Latin1 range.

export function encryptURIComponent(str) {
  // String => Uint8Array of UTF-8 bytes
  const utf8Bytes = new TextEncoder().encode(str)

  // Bytes => Binary string
  const binaryString = Array.from(utf8Bytes)
    .map((byte) => String.fromCharCode(byte))
    .join('')

  // Binary string => Base64
  const base64String = btoa(binaryString)

  // Reversal
  const reversedBase64String = base64String.split('').reverse().join('')

  // URI encoding
  const uriEncodedString = encodeURIComponent(reversedBase64String)

  return uriEncodedString
}

export function decryptURIComponent(encodedStr) {
  // Decode the URI component to get the reversed Base64 string
  const reversedBase64String = decodeURIComponent(encodedStr)
    .split('')
    .reverse()
    .join('')

  // Decode the Base64 string to get the binary string
  const binaryString = atob(reversedBase64String)

  // Convert the binary string to a Uint8Array of UTF-8 bytes
  const utf8Bytes = new Uint8Array(
    Array.from(binaryString).map((char) => char.charCodeAt(0)),
  )

  // Decode the bytes back to a UTF-8 string
  const str = new TextDecoder().decode(utf8Bytes)

  return str
}
