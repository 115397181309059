<script setup>
import { ref, toRefs } from 'vue'

const props = defineProps({
  modelValue: String,
  options: Array,
  columns: Number,
  name: String,
  displayName: String,
})

defineEmits(['update:modelValue'])

const { modelValue, options, columns, displayName } = toRefs(props)

const custom = ref('')

if (!options.value.map((o) => o.value).includes(modelValue)) {
  custom.value = modelValue.value
}
</script>

<template>
  <div class="grid gap-[20px] auto-rows-fr grid-cols-2 lg:grid-cols-3">
    <div
      v-for="(option, i) of options"
      :key="i"
      class="bg-[#0A0D22] bg-opacity-50 p-[15px] lg:p-[25px] rounded-[20px] border border-[#141A3D] border-opacity-70 cursor-pointer"
      :class="
        modelValue == option.value &&
        'outline outline-3 outline-offset-4 outline-purple-500'
      "
      @click="$emit('update:modelValue', option.value)"
    >
      <div
        class="flex justify-center items-center h-[40px] w-[40px] lg:h-[50px] lg:w-[50px] rounded-xl border border-white border-opacity-15"
        style="
          background: radial-gradient(
            255.14% 174.74% at 38.76% 155.71%,
            #cc47ff 0%,
            #9a5cff 100%
          );
        "
      >
        <img
          :src="option.icon"
          :alt="`${option.displayName} ${displayName}`"
          style="user-select: none; user-drag: none; pointer-events: none"
          draggable="false"
        />
      </div>
      <p class="lg:text-[19px] mt-5">{{ option.displayName }}</p>
    </div>
  </div>
  <!-- <div class="cursor-pointer mt-[22px] lg:mt-6 flex flex-col gap-1 text-white">
    <input
      v-model="custom"
      type="text"
      placeholder="Type a custom occupation..."
      class="block w-full rounded-[15px] pl-[19px] border border-[#111631] focus:outline-none placeholder:text-white/60 bg-[#0A0D1E] h-[62px] text-white ring-1 ring-inset ring-white/10"
      :class="
        (modelValue &&
          modelValue == custom &&
          'outline outline-3 outline-offset-4 outline-purple-500') ||
        ''
      "
      @input="$emit('update:modelValue', custom)"
    />
  </div> -->
</template>
