<script setup>
import { ref, toRefs } from 'vue'
import Popup from '@/components/Popup.vue'
import ButtonComponent from './ButtonComponent.vue'

const props = defineProps({
  open: Boolean,
  type: {
    type: String,
    default: 'question',
  },
  showDefault: {
    type: Boolean,
    default: true,
  },
  closeButton: {
    type: Boolean,
    default: true,
  },
  overlayExit: {
    type: Boolean,
    default: true,
  },
  buttonText: {
    type: Object,
    default: {},
  },
  reverse: {
    type: Boolean,
    default: false,
  },
  popupStyle: {
    type: String,
    default: '',
  },
  confirmStyle: {
    type: String,
    default: '',
  },
  mobile: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:open', 'confirm', 'cancel'])

const { open, type, buttonText, reverse, mobile } = toRefs(props)

function confirm() {
  emit('confirm')
}

function cancel() {
  emit('cancel')
}

function close() {
  emit('update:open', false)
}

const confirmText = ref(buttonText.value.confirm || 'Confirm')
const cancelText = ref(buttonText.value.cancel || 'Cancel')
</script>

<template>
  <Popup
    class="sticky z-40"
    :mobile="mobile"
    :open="open"
    :close-button="closeButton"
    :overlay-exit="overlayExit"
    :popupStyle="popupStyle"
    @update:open="emit('update:open', $event)"
  >
    <div :class="confirmStyle">
      <slot></slot>
      <div
        v-if="showDefault"
        class="flex justify-between mt-[22px] text-sm font-semibold gap-x-3"
      >
        <template v-if="type === 'question'">
          <ButtonComponent
            type="button"
            class="p-3 w-full mb-[1px] ml-[1px]"
            :variant="reverse ? 'primary' : 'secondary'"
            :borderVisible="reverse ? true : false"
            @click="!reverse ? cancel() : confirm()"
          >
            {{ !reverse ? cancelText : confirmText }}
          </ButtonComponent>
          <ButtonComponent
            type="button"
            class="p-3 w-full mb-[1px] mr-[1px]"
            :class="
              reverse
                ? 'bg-[#151831] text-gray-900'
                : 'bg-purple-500 text-white'
            "
            @click="!reverse ? confirm() : cancel()"
          >
            {{ !reverse ? confirmText : cancelText }}
          </ButtonComponent>
        </template>
        <template v-else>
          <ButtonComponent
            type="button"
            class="py-3 px-6 w-full"
            @click="confirm"
          >
            {{ confirmText }}
          </ButtonComponent>
        </template>
      </div>
      <slot name="footer"></slot>
    </div>
  </Popup>
</template>
