import { inject } from 'vue'
import { useToast } from 'vue-toast-notification'

export default function useLogout() {
  const $axios = inject('axios')
  const $toast = useToast()

  async function logout() {
    try {
      await $axios.post('/auth/signout')
    } catch (e) {
      const message = e?.response?.data?.message
      $toast.error(
        message || 'Unable to perform action. Please try again later.',
      )
      throw e
    }
  }

  return logout
}
