import mixpanel from 'mixpanel-browser'
import { useStore } from 'vuex'

export function useMixpanel() {
  const store = useStore()

  function track(
    eventName,
    eventArgs = {},
    { source = null, transport = 'xhr' } = { source: null, transport: 'xhr' },
  ) {
    mixpanel.track(
      eventName,
      {
        ...eventArgs,
        ...(source && {
          source:
            source == 'current'
              ? store.state.routes.current === ''
                ? 'home'
                : store.state.routes.current
              : source == 'previous'
                ? store.state.routes.previous === ''
                  ? 'home'
                  : store.state.routes.previous
                : source,
        }),
      },
      {
        transport,
      },
    )
  }

  return {
    track,
    ...mixpanel,
  }
}
