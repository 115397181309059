export function pickRandom(array) {
  const index = Math.floor(Math.random() * array.length)
  return array[index]
}

export function pickRandomMultiple(array, n) {
  if (n >= array.length) {
    return array.slice()
  }

  const pickedElements = new Set()
  while (pickedElements.size < n) {
    const index = Math.floor(Math.random() * array.length)
    pickedElements.add(array[index])
  }

  return Array.from(pickedElements)
}
