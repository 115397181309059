const generateSrcSet = (name, format, size = '', isChat = false) => {
  const basePath = `/models/${format}`
  if (isChat) {
    return `${basePath}/${name}-xs.${format}`
  }
  const sizeSuffix = size ? `-${size}` : ''
  return `${basePath}/${name}${sizeSuffix}.${format}`
}

const getSrcSet = (model, isChat = false) => {
  return {
    desktopAvif: [
      `${generateSrcSet(model, 'avif', 'lg-x1', isChat)} 1x`,
      `${generateSrcSet(model, 'avif', 'lg-x2', isChat)} 2x`,
    ].join(', '),
    desktopWebp: [
      `${generateSrcSet(model, 'webp', 'lg-x1', isChat)} 1x`,
      `${generateSrcSet(model, 'webp', 'lg-x2', isChat)} 2x`,
    ].join(', '),
    mobileAvif: [
      `${generateSrcSet(model, 'avif', 'sm-x1', isChat)} 1x`,
      `${generateSrcSet(model, 'avif', 'sm-x2', isChat)} 2x`,
      `${generateSrcSet(model, 'avif', 'sm-x3', isChat)} 3x`,
    ].join(', '),
    mobileWebp: [
      `${generateSrcSet(model, 'webp', 'sm-x1', isChat)} 1x`,
      `${generateSrcSet(model, 'webp', 'sm-x2', isChat)} 2x`,
      `${generateSrcSet(model, 'webp', 'sm-x3', isChat)} 3x`,
    ].join(', '),
  }
}

export { getSrcSet, generateSrcSet }
