import { ref } from 'vue'

export const sidebarOpen = ref(false)

const startX = ref(0)
const startY = ref(0)
const deltaX = ref(0)
const deltaY = ref(0)
export const touchstart = (e) => {
  startX.value = e.targetTouches[0]?.screenX
  startY.value = e.targetTouches[0]?.screenY
  deltaX.value = 0
  deltaY.value = 0
}
export const touchmove = (e) => {
  deltaX.value = e.targetTouches[0]?.screenX - startX.value
  deltaY.value = e.targetTouches[0]?.screenY - startY.value
}
export const touchend = () => {
  if (
    Math.abs(deltaX.value) > 100 &&
    Math.abs(deltaY.value) < Math.abs(deltaX.value)
  ) {
    sidebarOpen.value = deltaX.value > 0
  }
}
