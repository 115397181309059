import queryString from 'query-string'

function getLoginUrl(state) {
  const stringifiedParams = queryString.stringify({
    state: btoa(JSON.stringify(state)),
  })

  const loginUrl = `${import.meta.env.VITE_BASE_URL}/auth/twitter/init?${stringifiedParams}`
  console.log(loginUrl)
  return loginUrl
}

export default {
  getLoginUrl,
}
