import cdnFile from '@/helpers/cdnFile'

export default [
  {
    id: 'slice_of_pizza',
    name: 'Pizza',
    image: cdnFile('gifts/slice_of_pizza.webp', true),
    price: 20,
  },
  {
    id: 'ice_cream',
    name: 'Ice Cream',
    image: cdnFile('gifts/ice_cream.webp', true),
    price: 40,
  },
  {
    id: 'cake',
    name: 'Cake',
    image: cdnFile('gifts/cake.webp', true),
    price: 60,
  },
  {
    id: 'cocktail',
    name: 'Cocktail',
    image: cdnFile('gifts/cocktail.webp', true),
    price: 80,
  },
  {
    id: 'heart',
    name: 'Heart',
    image: cdnFile('gifts/heart.webp', true),
    price: 100,
  },
  {
    id: 'rose',
    name: 'Rose',
    image: cdnFile('gifts/rose.webp', true),
    price: 120,
  },
  {
    id: 'teddy_bear',
    name: 'Teddy Bear',
    image: cdnFile('gifts/teddy_bear.webp', true),
    price: 140,
  },
  {
    id: 'diamond',
    name: 'Diamond',
    image: cdnFile('gifts/diamond.webp', true),
    price: 160,
  },
  {
    id: 'tiara',
    name: 'Tiara',
    image: cdnFile('gifts/tiara.webp', true),
    price: 180,
  },
]
