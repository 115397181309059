export const SUBSCRIPTION_MONTHLY = 1
export const SUBSCRIPTION_ANNUALLY = 2

export const getPricing = (isProd) =>
  !isProd
    ? {
        subscriptions: [
          {
            name: 'LunaLust Pro',
            luna: 500,
            amount: '19.95',
            type: SUBSCRIPTION_MONTHLY,
          },
          {
            name: 'LunaLust Pro',
            luna: 500,
            prediscountAmount: '239.40',
            discountPercentage: '70',
            monthlyAmount: '6.65',
            amount: '79.80',
            type: SUBSCRIPTION_ANNUALLY,
          },
        ],
        purchases: [
          {
            amount: '10.95',
            luna: 200,
            name: 'Lunar<br>Breeze',
            image: 'packages/1.svg',
          },
          {
            amount: '29.95',
            prediscoutAmount: '39.99',
            luna: 800,
            name: 'Lunar<br>Odyssey',
            image: 'packages/2.svg',
            popular: true,
          },
          {
            amount: '49.95',
            prediscoutAmount: '89.99',
            luna: 1600,
            name: 'Lunar<br>Harmony',
            image: 'packages/3.svg',
          },
          {
            amount: '99.95',
            prediscoutAmount: '179.99',
            luna: 3600,
            name: 'Lunar<br>Eclipse',
            image: 'packages/4.svg',
            bestDeal: true,
          },
        ],
      }
    : {
        subscriptions: [
          {
            name: 'LunaLust Pro',
            luna: 500,
            amount: '19.95',
            type: SUBSCRIPTION_MONTHLY,
          },
          {
            name: 'LunaLust Pro',
            luna: 500,
            prediscountAmount: '239.40',
            discountPercentage: '70',
            monthlyAmount: '6.65',
            amount: '79.80',
            type: SUBSCRIPTION_ANNUALLY,
          },
        ],
        purchases: [
          {
            amount: '10.95',
            luna: 200,
            name: 'Lunar<br>Breeze',
            image: 'packages/1.svg',
          },
          {
            amount: '29.95',
            prediscoutAmount: '39.99',
            luna: 800,
            name: 'Lunar<br>Odyssey',
            image: 'packages/2.svg',
            popular: true,
          },
          {
            amount: '49.95',
            prediscoutAmount: '89.99',
            luna: 1600,
            name: 'Lunar<br>Harmony',
            image: 'packages/3.svg',
          },
          {
            amount: '99.95',
            prediscoutAmount: '179.99',
            luna: 3600,
            name: 'Lunar<br>Eclipse',
            image: 'packages/4.svg',
            bestDeal: true,
          },
        ],
      }
