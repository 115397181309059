<script setup>
import uploadBgShade from '@/assets/img/particles.svg'
import { getCurrentInstance, ref } from 'vue'

const uploadText = ref(null)

const { emit } = getCurrentInstance()

const props = defineProps({
  icon: {
    type: Object,
    required: true,
  },
})

defineEmits(['file-input-change', 'before-file-upload'])

const onDragOver = () => {
  uploadText.value = 'Drop here'
}
const onDragLeave = () => {
  uploadText.value = null
}
const onDrop = (event) => {
  uploadText.value = null

  const files = event.dataTransfer.files
  if (files.length) {
    event.preventDefault()
    emit('before-file-upload', files[0], (proceed) => {
      if (!proceed) return
      else {
        emit('file-input-change', files[0])
      }
    })
  }
}

const uploadImage = async (event) => {
  const file = event.target.files[0]

  if (file) {
    emit('before-file-upload', file, (proceed) => {
      if (!proceed) return
      emit('file-input-change', file)
    })
  }
}

const triggerFileInput = () => {
  const fileInput = document.getElementById('file-upload')
  if (fileInput) {
    emit('before-file-upload', null, (proceed) => {
      if (proceed) {
        fileInput.click()
      }
    })
  }
}

const backgroundStyle = {
  backgroundImage: `url(${uploadBgShade})`,
  border: '1px solid rgba(20, 26, 61, 0.7)',
}
</script>
<template>
  <div
    class="max-w-[1450px]"
    @dragover.prevent="onDragOver"
    @dragleave.prevent="onDragLeave"
    @drop.prevent="onDrop"
  >
    <div class="min-h-full relative">
      <div
        class="flex w-full flex-grow justify-center outline-none lg:h-auto h-full"
      >
        <div
          class="upload-image lg:min-h-[500px] max-h-[800px] rounded-[32px] p-[10px] lg:p-[27px] border h-[60vh] w-full"
        >
          <div
            class="flex justify-center flex-col gap-[30px] items-center h-full bg-bottom bg-no-repeat bg-auto rounded-[25px] bg-[#0a0d21]"
            :style="backgroundStyle"
          >
            <div class="flex flex-col items-center gap-[15px] lg:gap-[30px]">
              <div
                class="upload-image-icon-wrap w-[100px] h-[100px] lg:w-[139px] lg:h-[139px] rounded-[34px] lg:rounded-[48px] flex items-center justify-center"
              >
                <component
                  :is="icon"
                  :size="78"
                  class="w-[56px] lg:w-[78px] h-auto"
                />
              </div>
              <div>
                <p v-if="uploadText" class="text-2xl font-regular">
                  {{ uploadText }}
                </p>
                <slot v-else name="text"> </slot>
              </div>
            </div>
            <form>
              <label
                for="file-upload"
                class="cursor-pointer block py-[13px] px-[81px] font-medium rounded-[15px]"
                :style="{
                  background:
                    'radial-gradient(255.14% 174.74% at 38.76% 155.71%, #CC47FF 0%, #9A5CFF 100%)',
                  'box-shadow':
                    '0px 0px 3px 0px #1CAE6E, 0px 0px 0px 1.18px #B552FF',
                }"
                @click.prevent="triggerFileInput"
              >
                Upload
              </label>
              <input
                id="file-upload"
                type="file"
                accept=".jpg, .jpeg, .png, .heic"
                class="hidden"
                @change="uploadImage"
              />
            </form>
            <slot name="after-button"> </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.canvas-wrapper {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.upload-image {
  border-color: rgba(20, 26, 61, 0.7);
  background-color: rgba(10, 13, 34, 0.5);
}
form {
  border-color: rgba(20, 26, 61, 0.7);
  background-color: rgba(13, 17, 42, 0.3);
}
.upload-image-icon-wrap {
  border: 2.87px solid rgba(255, 255, 255, 0.05);
}
</style>
