import { ref } from 'vue'
import store from '@/stores/index.js'

export function usePopup() {
  const title = ref('')
  const content = ref('')

  function openPopup(newTitle, newContent) {
    title.value = newTitle
    content.value = newContent
    store.commit('SET_GENERIC_POPUP', {
      open: true,
      title: title.value,
      content: content.value,
    })
  }

  return {
    title,
    content,
    openPopup,
  }
}
