<script setup>
import { ref, watch, toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useLoading } from 'vue-loading-overlay'
import { useMixpanel } from '@/composables/mixpanel'
import Popup from './Popup.vue'
import RegisterModal from '@/components/RegisterModal.vue'
import logo from '@/assets/logo.svg'

const props = defineProps({
  open: Boolean,
  text: String,
})
const { open } = toRefs(props)

const $loading = useLoading({
  canCancel: false,
  isFullPage: true,
  color: '#a15cff',
})
const $store = useStore()
const emit = defineEmits(['update:open'])
const premadeModel = computed(() => $store.state.premadeModel)

const mixpanel = useMixpanel()
const router = useRouter()
const route = useRoute()

const validationOpen = ref(false)

watch(validationOpen, (newVal) => {
  if (newVal) {
    emit('update:open', false)
    router.push('/register')
  }
})

watch(
  () => route.path,
  (newPath) => {
    if (newPath === '/login') {
      emit('update:open', false)
    }
  },
)

watch(open, (val) => {
  if (val) {
    const freeLimitHit = $store.state.freeLimitHit
    mixpanel.track(
      'open_login_register_popup',
      { free_limit_hit: freeLimitHit },
      {
        source: 'current',
      },
    )
  }
})
</script>

<template>
  <Popup
    class="sticky"
    :open="open"
    :mobile="false"
    popupStyle="bg-[#0A0D22] w-[625px] pt-[50px] pb-5 px-4 lg:py-[74px] mx-4 lg:mx-0"
    @update:open="emit('update:open', $event)"
  >
    <div>
      <div class="flex flex-col items-center gap-[25px] text-center">
        <img class="w-[148px] h-auto" :src="logo" alt="Logo" />
        <h2 class="text-[30px] lg:text-[35px] leading-[1]">
          Create a Free Account
        </h2>
      </div>
      <div class="lg:px-6">
        <RegisterModal
          :with-accept-policies="true"
          :model-ids="premadeModel ? [premadeModel.id] : []"
          :redirectPath="decodeURIComponent($store.state.lastAppPage)"
          v-model:validationOpen="validationOpen"
          @registered="() => emit('update:open', false)"
        />
      </div>
      <p class="text-sm text-[#B1B5DB] text-center pt-5">
        Already a member?
        {{ ' ' }}
        <router-link to="/login" class="text-[#CC47FF]">Log in</router-link>
      </p>
    </div>
  </Popup>
</template>
