import { computed, reactive } from 'vue'
import { useStore } from 'vuex'

export function useDiscountTimer() {
  const $store = useStore()

  const endDate = computed(() => $store.state.discountEndDate)

  const time = reactive({
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

  const startTimer = () =>
    setInterval(() => {
      const now = new Date().getTime()
      const timeleft = endDate.value - now

      if (!timeleft || timeleft <= 0) {
        clearTimer()
        $store.commit('SET_DISCOUNT_CODE', {})
        $store.commit('SET_DISCOUNT_POPUP', false)

        const path = window.location.pathname
        window.history.replaceState({}, document.title, path)
        window.location.reload()
        return
      }

      time.hours = Math.floor(timeleft / (1000 * 60 * 60))
      time.minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60))
      time.seconds = Math.floor((timeleft % (1000 * 60)) / 1000)

      time.display = `${time.hours ? (time.hours < 10 ? `0${time.hours}:` : `${time.hours}:`) : ''}${time.minutes < 10 ? `0${time.minutes}` : `${time.minutes}`}:${time.seconds < 10 ? `0${time.seconds}` : `${time.seconds}`}`
    }, 1000)

  const clearTimer = () => clearInterval(startTimer)

  if (!endDate.value) {
    return { time: null, startTimer: () => {}, clearTimer: () => {} }
  }

  return { time, startTimer, clearTimer }
}
