export default {
  messageCap: 14,
  globalMessageCap: 28,
  customModelCap: 2,
  imagesCap: 2,
  noAuthMessageCap: 7,
  generatorImagesBlurCap: 1,
  generatorImagesCap: 2,
  chatImagesBlurCap: 2,
  freeRatings: 1,
}
