<script setup>
import { ref, onMounted, onUnmounted, watch, nextTick, toRefs } from 'vue'

const props = defineProps({
  content: {
    type: String,
    default: '',
  },
})

const { content } = toRefs(props)

const contentHolder = ref(null)
const contentInnerContainer = ref(null)
const isFiting = ref(false)

const handleResize = () => {
  if (isFiting.value) return
  if (!contentInnerContainer.value || !contentHolder.value) return

  nextTick(() => {
    isFiting.value = true
  })

  setTimeout(() => {
    contentInnerContainer.value.style.width = 'unset'
    setTimeout(() => {
      contentInnerContainer.value.style.width = `${contentHolder.value.offsetWidth + 1}px`
      nextTick(() => {
        isFiting.value = false
      })
    }, 0)
  }, 0)
}

onMounted(() => {
  window.addEventListener('resize', handleResize)
  setTimeout(handleResize, 0)
})

watch(content, handleResize, { immediate: true, deep: true })

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
</script>

<template>
  <div ref="contentInnerContainer">
    <div ref="contentHolder" class="inline" v-html="content"></div>
  </div>
</template>
