export default function getAffiliateTrackingParams() {
  // Get the current URL
  const url = window.location.href

  // Check if there's a hash part in the URL
  const hashIndex = url.indexOf('#')
  if (hashIndex === -1) {
    return {}
  }

  // Extract the hash part (excluding the '#')
  const hash = url.substring(hashIndex + 1)

  // Split the hash part into key-value pairs
  const paramsArray = hash.split('&')

  // Convert the key-value pairs into an object
  const params = {}
  paramsArray.forEach((param) => {
    const [key, value] = param.split('=')
    if (key && value !== undefined) {
      params[key] = decodeURIComponent(value)
    }
  })

  return params
}
