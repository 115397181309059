import queryString from 'query-string'

function getLoginUrl(state) {
  const stringifiedParams = queryString.stringify({
    client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
    redirect_uri: `${import.meta.env.VITE_BASE_URL}/auth/google`,
    scope: [
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile',
    ].join(' '), // space seperated string
    response_type: 'code',
    prompt: 'consent',
    state: btoa(JSON.stringify(state)),
  })

  const loginUrl = `https://accounts.google.com/o/oauth2/v2/auth?${stringifiedParams}`

  return loginUrl
}

export default {
  getLoginUrl,
}
