import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toast-notification'
import { getPricing } from 'common/lib/pricing'
import { useStore } from 'vuex'

const purchaseInfo = getPricing(import.meta.env.VITE_ENV !== 'dev').purchases

export function usePaymentNotifier(openSubscribeCb, openBuyLunaCb) {
  const $store = useStore()
  const $toast = useToast()
  const $route = useRoute()
  const $router = useRouter()

  const paymentStatus = $route.query.paymentStatus

  if (!$route || !$route.query || !paymentStatus) return

  const newQuery = structuredClone($route.query)

  const paymentErrorSuffix = $route.query.details
    ? ` (${$route.query.details}). Please try again`
    : '. Please try again'
  if ($route.query.changeCard === 'true') {
    if (paymentStatus === 'failure') {
      $toast.error(`Failed to change card${paymentErrorSuffix}`)
    } else if (paymentStatus === 'success') {
      $toast.success(`Card changed successfully`)
    }
    delete newQuery.changeCard
  } else if ($route.query.purchaseNumber) {
    if (paymentStatus === 'failure') {
      openBuyLunaCb && openBuyLunaCb()
      $toast.error(`Payment Failed${paymentErrorSuffix}`)
    } else if (paymentStatus === 'success') {
      $store.commit('SET_DISCOUNT_CODE', {})
      $toast.success(
        `Payment successful. ${purchaseInfo[$route.query.purchaseNumber].luna} luna credited to your account`,
      )
    }
    delete newQuery.purchaseNumber
  } else if (paymentStatus === 'failure') {
    openSubscribeCb && openSubscribeCb()
    $toast.error(`Payment Failed${paymentErrorSuffix}`)
  } else if (paymentStatus === 'success') {
    $store.commit('SET_DISCOUNT_CODE', {})
    $toast.success('Payment successful.')
  }

  if (paymentStatus === 'failure') delete newQuery.details
  delete newQuery.paymentStatus

  $router.replace({ query: newQuery })
}
