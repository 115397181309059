<script setup>
import { ref, toRefs, computed } from 'vue'

const props = defineProps({
  modelValue: String,
  customValue: String,
  isCustom: Boolean,
  options: Array,
  columns: Number,
  name: String,
  displayName: String,
})

const custom = ref(props.customValue)

const { modelValue, options, columns, displayName } = toRefs(props)

if (!options.value.map((o) => o.value).includes(modelValue)) {
  custom.value = modelValue.value
}

defineEmits(['update:modelValue', 'update:customValue'])

const regularOptions = computed(() =>
  props.options.filter((o) => !o.special && !o.custom),
)
const specialOption = computed(() => props.options.filter((o) => o.special)[0])
</script>

<template>
  <div>
    <div
      class="grid gap-3 md:gap-5 lg:gap-6 auto-rows-fr grid-cols-2 lg:grid-cols-3"
    >
      <div
        v-for="(option, i) of regularOptions"
        :key="i"
        class="bg-[#0A0D22] bg-opacity-50 p-[15px] lg:p-[25px] rounded-[20px] cursor-pointer flex flex-col gap-3 border border-[#141A3D] border-opacity-70"
        :class="
          modelValue === option.value &&
          'outline outline-3 outline-offset-4 outline-purple-500'
        "
        @click="
          () => {
            $emit('update:modelValue', option.value)
            $emit('update:customValue', null)
          }
        "
      >
        <div
          class="flex justify-center items-center h-[40px] w-[40px] lg:h-[50px] lg:w-[50px] rounded-xl border border-white border-opacity-15"
          style="
            background: radial-gradient(
              255.14% 174.74% at 38.76% 155.71%,
              #cc47ff 0%,
              #9a5cff 100%
            );
          "
        >
          <img
            :src="option.icon"
            :alt="`${option.displayName} ${displayName}`"
            style="user-select: none; user-drag: none; pointer-events: none"
            draggable="false"
          />
        </div>
        <p class="leading-[1]">
          {{ option.displayName }}
        </p>
        <p class="text-xs lg:text-sm text-[#B1B5DB]">
          {{ option.displayDescription }}
        </p>
      </div>
    </div>
    <!-- <div class="mt-[22px] lg:mt-6">
      <input
        :value="custom"
        type="text"
        placeholder="Type a custom personality..."
        class="block w-full rounded-[15px] pl-[19px] border border-[#111631] focus:outline-none placeholder:text-white/60 bg-[#0A0D1E] h-[62px] text-white"
        :class="
          (modelValue &&
            modelValue == custom &&
            'outline outline-3 outline-offset-4 outline-purple-500') ||
          ''
        "
        @input="
          (event) => {
            custom = event.target.value
            $emit('update:customValue', custom.replace(/\n/g, ' '))
            $emit('update:modelValue', null)
          }
        "
      />
    </div> -->
  </div>
</template>
