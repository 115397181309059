<script setup>
import { inject, onMounted, toRaw } from 'vue'
import { useRoute } from 'vue-router'

const $axios = inject('axios')
const $route = useRoute()

const checkStatus = async () => {
  try {
    const { data } = await $axios.post('/user/payment/check-status', {
      id: $route.query.id,
    })

    const messageData = {
      ...(data.user && { user: data.user }),
      ...(typeof data.purchaseNumber === 'number' && {
        purchaseNumber: data.purchaseNumber,
      }),
      ...(data.cardData && { cardData: data.cardData }),
    }

    window.parent.postMessage(messageData)
  } catch (e) {
    const message = e.response.data?.message
    window.parent.postMessage({
      message: message || 'Unable to perform action. Please try again later.',
      isPaymentError: true,
    })
    throw e
  }
}

onMounted(checkStatus)
</script>

<template></template>
