<script setup>
import { PlayIcon, PauseIcon } from '@heroicons/vue/20/solid'
import play from '@/assets/icons/play.svg'
import { ref, toRefs, watch, onMounted, onUnmounted } from 'vue'

const props = defineProps({
  active: Boolean,
  src: String,
})

const { src, active } = toRefs(props)

const audioRef = ref(null)
const isPlaying = ref(false)
const duration = ref(0) // The max length of the message in seconds
const remainingTime = ref('')
const waveCount = ref(0)
const durationInterval = ref(null)

const wavesLength = [5, 15, 5, 10, 4]

// Function to format time in mm:ss format
const formatTime = (time) => {
  const minutes = Math.floor(time / 60)
  const seconds = Math.floor(time % 60)
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
}

// Function to initialize the duration once the metadata is loaded
function initDuration() {
  duration.value = audioRef.value.duration
  remainingTime.value = formatTime(duration.value)
}

// Function to update the timer
function updateTime() {
  remainingTime.value = formatTime(
    duration.value - (audioRef.value?.currentTime || 0),
  )
}

// Function to handle the audio ended event
function onEnded() {
  isPlaying.value = false
  audioRef.value.currentTime = 0
  updateTime()
  clearInterval(durationInterval.value)
  waveCount.value = 0
}

// Function to toggle play/stop
function togglePlay() {
  if (isPlaying.value) {
    audioRef.value.pause()
    clearInterval(durationInterval.value)
  } else {
    audioRef.value.play()
    durationInterval.value = setInterval(() => {
      waveCount.value++
    }, 1000)
  }
  isPlaying.value = !isPlaying.value
}

watch(active, (newValue) => {
  if (!newValue) {
    if (isPlaying.value) {
      audioRef.value.pause()
      clearInterval(durationInterval.value)
      waveCount.value = 0
      audioRef.value.currentTime = 0
      isPlaying.value = false
    }
  }
})

onUnmounted(() => {
  if (isPlaying.value) {
    audioRef.value?.pause()
  }
})
</script>

<template>
  <div
    class="inline-flex items-center cursor-pointer gap-x-2 py-0.5"
    @click="togglePlay"
  >
    <!-- Play/Stop button -->
    <button type="button" class="focus:outline-none">
      <!-- <PlayIcon v-if="!isPlaying" class="w-5 h-5" /> -->
      <img v-if="!isPlaying" :src="play" class="w-6 h-6" />
      <PauseIcon v-else class="w-6 h-6" />
    </button>

    <slot></slot>

    <!-- Progress bar (hidden for now as Instagram does not show it, but you can implement if needed) -->
    <!-- <div class="flex-1 mx-4">
      <progress value="0" max="100" class="w-full"></progress>
    </div> -->
    <div class="flex gap-[5px] items-center h-[15px]">
      <div
        v-for="wave in Array(waveCount)
          .fill(0)
          .map((_, i) => i)"
        :key="wave"
        class="bg-white w-[2px] rounded-full"
        :style="`height:${wavesLength[wave % wavesLength.length]}px`"
      ></div>
    </div>

    <!-- Timer -->
    <div class="text-xs font-semibold">
      {{ remainingTime }}
    </div>

    <audio
      ref="audioRef"
      :src="src"
      hidden
      @loadedmetadata="initDuration"
      @timeupdate="updateTime"
      @ended="onEnded"
    ></audio>
  </div>
</template>
