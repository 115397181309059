<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import ButtonComponent from '@/components/ButtonComponent.vue'
import xClose from '@/assets/icons/x-close.svg'
import logo from '@/assets/logo.svg'
import Compass from '@/components/icons/Compass.vue'
import Chat from '@/components/icons/Chat.vue'
import Saved from '@/components/icons/Saved.vue'
import Generate from '@/components/icons/Generate.vue'
import Logout from '@/components/icons/Logout.vue'
import Settings from '@/components/icons/Settings.vue'
import Eggplant from '@/components/icons/Eggplant.vue'
import NavigationListItem from '@/components/Navigation/NavigationListItem.vue'
import useNavigationMenu from '@/composables/useNavigationMenu'

const $store = useStore()
const $route = useRoute()

const mobileMenuOpen = computed(() => $store.state.mobileMenuOpen)
const isHomePage = computed(() =>
  ['/', '/ai-boyfriend', '/ai-hentai'].includes($route.path),
)
const user = computed(() => $store.state.user)

const {
  goHome,
  goToGenerator,
  goToChat,
  goToSaved,
  goToRater,
  goToSettings,
  logout,
} = useNavigationMenu()

function toggleMobileMenu(value) {
  $store.commit('SET_MOBILE_MENU_OPEN', {
    open: value,
  })
}
</script>

<template>
  <div
    v-show="mobileMenuOpen"
    class="w-full fixed h-full flex flex-col px-[36px] py-[33px] bg-[#070917] overflow-hidden"
    style="z-index: 99999"
  >
    <ButtonComponent
      variant="close"
      class="p-3 absolute top-[15px] right-[36px] border-none"
      @click="toggleMobileMenu(false)"
    >
      <img :src="xClose" alt="Close icon" class="w-[24px] h-[24px]" />
    </ButtonComponent>
    <div class="mb-[87px]">
      <router-link to="/" @click.prevent.stop.capture="goHome(true)">
        <img class="w-auto h-[17px]" :src="logo" alt="Logo" />
      </router-link>
    </div>
    <nav class="flex flex-col grow space-between rounded-t-[15px]">
      <ul role="list" class="flex flex-1 flex-col gap-y-[15px]">
        <NavigationListItem
          :to="isHomePage ? $route.path : '/'"
          label="Explore"
          :icon="Compass"
          :active="isHomePage"
          :hideLabel="false"
          @click.prevent.stop.capture="goHome(false)"
        />
        <NavigationListItem
          to="/nsfw-ai-image-generator"
          label="Generator"
          :icon="Generate"
          :hideLabel="false"
          :active="$route.path === '/nsfw-ai-image-generator'"
          @click.prevent.stop.capture="goToGenerator"
        />
        <NavigationListItem
          to="/chat"
          label="Chat"
          :icon="Chat"
          :hideLabel="false"
          :active="$route.path === '/chat'"
          @click.prevent.stop.capture="goToChat"
        />
        <NavigationListItem
          to="/saved"
          label="Saved"
          :icon="Saved"
          :hideLabel="false"
          :active="$route.path === '/saved'"
          @click.prevent.stop.capture="goToSaved"
        />
        <NavigationListItem
          to="/rate-my-cock"
          label="Rater"
          :icon="Eggplant"
          :active="$route.path === '/rate-my-cock'"
          :hideLabel="false"
          @click.prevent.stop.capture="goToRater"
        />
        <NavigationListItem
          to="/settings"
          label="Settings"
          :icon="Settings"
          :hideLabel="false"
          :active="$route.path === '/settings'"
          @click.prevent.stop.capture="goToSettings"
        />
      </ul>
      <ul class="flex flex-col pt-[15px]">
        <NavigationListItem
          v-if="user"
          to="/"
          label="Logout"
          :icon="Logout"
          :active="false"
          :hideLabel="false"
          :externalLink="true"
          @click.prevent.stop.capture="logout"
        />
      </ul>
    </nav>
  </div>
</template>
