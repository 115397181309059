<script setup>
import { ref, computed, inject, onMounted } from 'vue'
import { useStore } from 'vuex'
import cdnFile from '@/helpers/cdnFile.js'
import premadeModels from 'common/lib/premade.js'
import { useRoute, useRouter } from 'vue-router'
import { useHead } from '@unhead/vue'
import { useLoading } from 'vue-loading-overlay'
import { useToast } from 'vue-toast-notification'
import Chat from '@/components/icons/Chat.vue'
import checkmark from '@/assets/icons/checkmark.svg'
import ButtonComponent from '../components/ButtonComponent.vue'
import AppHeader from '../components/AppHeader.vue'
import { useMixpanel } from '@/composables/mixpanel'
import queryString from 'query-string'
import { encryptURIComponent } from '@/helpers/uriEncrypt'
import GenerateIcon from '@/components/icons/Generate.vue'
import Image from '@/components/icons/navigation/Image.vue'
import { generateSrcSet, getSrcSet } from '@/helpers/srcSetHelper'

const mixpanel = useMixpanel()

const loader = ref(null)
const $store = useStore()

const $loading = useLoading({
  canCancel: false,
  isFullPage: true,
  color: '#a15cff',
})
const $toast = useToast()
const $axios = inject('axios')

const $router = useRouter()
const $route = useRoute()

const user = computed(() => $store.state.user)
const discountPopupOpen = computed(() => $store.state.discountPopupOpen)
const premadeModel = computed(() => $store.state.premadeModel)

const modelRef = computed(() =>
  premadeModels.find(
    (pmodel) => pmodel.model.name.toLowerCase() === $route.params.modelName,
  ),
)

mixpanel.track(
  'model_details_page_visit',
  {
    model_id: modelRef.value.model.premadeId,
    model_name: modelRef.value.model.name,
    model_gender: modelRef.value.model.gender,
  },
  { source: 'previous' },
)

const modelGender = computed(() => {
  if (modelRef.value) {
    return modelRef.value.model.gender === 'female' ? 'Girlfriend' : 'Boyfriend'
  }
  return null
})

const modelAttributes = computed(() => {
  if (modelRef.value) {
    /* eslint-disable no-unused-vars */
    const {
      name,
      gender,
      random,
      voiceId,
      referenceImage,
      premadeId,
      customPersonality,
      hobbies,
      lore,
      ...rest
    } = modelRef.value.model

    return Object.keys(rest).map((key) => ({ label: key, value: rest[key] }))
  }
  return []
})

const hobbies = computed(() => {
  return modelRef.value.model.hobbies
})

console.log(hobbies.value)

const headTitle = `${import.meta.env.VITE_BRAND_NAME} | AI ${modelGender.value} | ${modelRef.value.model.name}`
const headDescription = modelRef.value.lore.match(/<p>(.*?)<\/p>/)[1]
const headUrl = `https://${import.meta.env.VITE_DOMAIN_NAME}/ai-${modelGender.value.toLowerCase()}/${modelRef.value.model.name.toLowerCase()}`
const headImage = cdnFile(modelRef.value.model.referenceImage)

useHead({
  title: headTitle,
  meta: [
    { name: 'description', content: headDescription },

    { property: 'og:title', content: headTitle },
    { property: 'og:description', content: headDescription },
    { property: 'og:url', content: headUrl },
    { property: 'og:image', content: headImage },

    { name: 'twitter:title', content: headTitle },
    { name: 'twitter:description', content: headDescription },
    { name: 'twitter:url', content: headUrl },
    { name: 'twitter:image', content: headImage },
  ],
})

const redirectToGenerator = (modelId) => {
  const redirectQuery = queryString.stringify({
    modelId: encryptURIComponent(modelId),
  })

  $router.push('/nsfw-ai-image-generator' + '?' + redirectQuery)
}

async function createModel(model) {
  if (!user.value && premadeModel.value?.premadeId === model.premadeId) {
    $store.commit('SET_SELECTED_MODEL', model.id)
    await $router.push('/chat')
  } else if (user.value) {
    const userModel = user.value.models.filter(
      (el) => el.premadeId === model.premadeId,
    )
    if (userModel.length) {
      $store.commit('SET_SELECTED_MODEL', userModel[0].id)
      await $router.push('/chat')
    } else {
      loader.value = $loading.show()
      await $axios
        .post('/user/create-model/premade', {
          model,
        })
        .then(({ data: { model: createdModel } }) => {
          $store.commit('ADD_MODEL', createdModel)
          $store.commit('SET_SELECTED_MODEL', createdModel.id)
          $store.commit('SET_MESSAGES', createdModel.messages)
          $router.push('/chat')
        })
        .catch((e) => {
          const message = e?.response?.data?.message
          return $toast.error(message || 'An error occurred')
        })
      loader.value.hide()
    }
  } else {
    await $store.commit('SET_PREMADE_MODEL', model)
    await $store.commit('SET_SELECTED_MODEL', model.premadeId)
    await $router.push('/chat')
    await $axios
      .post('/model/create/premade', {
        model,
      })
      .then(({ data: { model: createdModel, changedLuna } }) => {
        $store.commit('SET_PREMADE_MODEL', createdModel)
        $store.commit('SET_SELECTED_MODEL', createdModel.id)
      })
      .catch((e) => {
        const message = e?.response?.data?.message
        return $toast.error(message || 'An error occurred')
      })
  }
}

function startUpperCase(text) {
  if (typeof text === 'string') {
    return text.charAt(0).toUpperCase() + text.slice(1)
  }
  return text
}

function toStartCase(text) {
  const result = text.replace(/([A-Z])/g, ' $1')
  return startUpperCase(result)
}

console.log(modelAttributes.value)
</script>

<template>
  <section class="lg:ml-[280px]" :class="discountPopupOpen ? 'pt-16' : ''">
    <AppHeader />
    <div class="px-4 xl:px-16 3xl:mx-auto m-auto max-w-[1450px]">
      <div
        class="lg:py-[27px] lg:px-[35px] lg:bg-[#0A0D22] bg-opacity-50 rounded-[25px] lg:border border-[#141A3D] border-opacity-70"
      >
        <div class="flex flex-col lg:flex-row gap-[30px] lg:gap-[43px]">
          <div class="lg:hidden flex flex-col">
            <h1 class="text-[26px] leading-[35px] mb-[10px] lg:mb-[25px]">
              {{ modelRef.model.name }}
            </h1>
            <h2 class="text-[14px] font-normal mb-[25px] text-[#B1B5DB]">
              {{ modelRef.phrase }}
            </h2>
            <ButtonComponent
              type="button"
              class="flex justify-center items-center gap-[11px] py-[14px] mb-[10px]"
              @click="createModel(modelRef.model)"
            >
              <component :is="Chat" />
              Chat
            </ButtonComponent>
            <ButtonComponent
              type="button"
              variant="secondary"
              :borderVisible="false"
              class="flex justify-center items-center gap-[11px] py-[14px]"
              @click="redirectToGenerator(modelRef.model.premadeId)"
            >
              <GenerateIcon :size="24"></GenerateIcon>
              Generate Picture
            </ButtonComponent>
          </div>
          <div
            class="self-center lg:self-auto relative overflow-hidden cursor-pointer w-full min-w-[300px] max-w-[75vw] sm:min-w-unset lg:w-[316px] sm:max-w-full sm:w-[calc((100%-1.5rem)/2)] md:w-[calc((100%-3rem)/2)] xl:w-[calc((100%-4.5rem)/4)]"
          >
            <picture
              v-if="modelRef.model.premadeId"
              class="flex justify-center"
            >
              <source
                :srcset="getSrcSet(modelRef.model.name).mobileAvif"
                media="(max-width: 450px)"
                type="image/avif"
              />
              <source
                :srcset="getSrcSet(modelRef.model.name).mobileWebp"
                media="(max-width: 450px)"
                type="image/webp"
              />

              <source
                :srcset="getSrcSet(modelRef.model.name).desktopAvif"
                media="(min-width: 451px)"
                type="image/avif"
              />
              <source
                :srcset="getSrcSet(modelRef.model.name).desktopWebp"
                media="(min-width: 451px)"
                type="image/webp"
              />
              <img
                class="lg:w-full w-[calc((100vw-1rem)/2)] aspect-[832/1216] rounded-[20px]"
                :src="generateSrcSet(modelRef.model.name, 'webp', 'lg-x1')"
                :alt="modelRef.model.name"
                style="user-select: none; user-drag: none; pointer-events: none"
                draggable="false"
                loading="eager"
                fetchpriority="high"
                decoding="async"
              />
            </picture>
            <div v-else class="flex justify-center">
              <img
                class="lg:w-full w-[calc((100vw-1rem)/2)] aspect-[832/1216] rounded-[20px]"
                :src="cdnFile(modelRef.model.referenceImage)"
                :alt="modelRef.model.name"
                style="user-select: none; user-drag: none; pointer-events: none"
                draggable="false"
                loading="eager"
              />
            </div>
          </div>
          <div
            class="hidden mt-[40px] lg:flex flex-col justify-start align-baseline h-full w-full"
          >
            <div class="max-w-[700px]">
              <h1 class="text-[26px] leading-[35px] mb-[10px]">
                {{ modelRef.model.name }}
              </h1>
              <h2 class="text-[#B1B5DB] mb-[23px]">
                {{ modelRef.phrase }}
              </h2>
            </div>
            <div class="flex gap-[25px] mb-[35px]">
              <ButtonComponent
                type="button"
                class="flex gap-[11px] py-[14px] pl-[18px] px-[26px] self-start"
                @click="createModel(modelRef.model)"
              >
                <component :is="Chat" :size="24" />
                Chat
              </ButtonComponent>
              <ButtonComponent
                type="button"
                variant="secondary"
                :borderVisible="false"
                class="flex gap-[11px] py-[14px] pl-[18px] px-[26px] self-start"
                @click="redirectToGenerator(modelRef.model.premadeId)"
              >
                <GenerateIcon :size="24"></GenerateIcon>
                Generate Picture
              </ButtonComponent>
            </div>
            <div
              class="w-full grid xl:grid-cols-4 grid-cols-[repeat(auto-fit,minmax(135px,auto))] gap-x-3 gap-y-[10px]"
            >
              <div v-for="attr in modelAttributes" :key="attr.value">
                <div
                  class="flex justify-center items-center bg-[#0E132D] gap-[10px] py-[9px] px-[8px] rounded-[10px] border border-[#141A3D] border-opacity-70"
                >
                  <span
                    class="flex justify-center items-center w-[34px] h-[34px] rounded-[10px]"
                    style="
                      background: rgb(10, 13, 30);
                      box-shadow:
                        rgb(30, 26, 61) 0px 0px 0px 1px,
                        rgb(19, 20, 46) 0px 0px 10px 2px inset;
                    "
                  >
                    <img
                      class="w-[14px] h-auto"
                      :src="checkmark"
                      alt="checkmark"
                    />
                  </span>
                  <div class="flex-1 flex flex-col">
                    <p class="text-sm text-[#B1B5DB]">
                      {{ toStartCase(attr.label) }}
                    </p>
                    <h3 class="text-[14px] leading-5">
                      {{
                        Array.isArray(attr.value) && attr.value.length > 0
                          ? startUpperCase(attr.value[0])
                          : startUpperCase(attr.value)
                      }}
                      <template
                        v-if="attr.label == 'personality' && attr.value == ''"
                      >
                        Adaptive
                      </template>
                    </h3>
                  </div>
                </div>
              </div>
              <template
                v-if="hobbies"
                v-for="(hobby, i) in hobbies"
                :key="hobby"
              >
                <div
                  class="flex justify-center items-center bg-[#0E132D] gap-[10px] py-[9px] px-[8px] rounded-[10px] border border-[#141A3D] border-opacity-70"
                >
                  <span
                    class="flex justify-center items-center w-[34px] h-[34px] rounded-[10px]"
                    style="
                      background: radial-gradient(
                        255.14% 174.74% at 38.76% 155.71%,
                        #cc47ff 0%,
                        #9a5cff 100%
                      );
                    "
                  >
                    <img
                      class="w-[14px] h-auto"
                      :src="checkmark"
                      alt="checkmark"
                    />
                  </span>
                  <div class="flex-1 flex flex-col">
                    <p class="text-sm text-[#B1B5DB]">Hobby {{ i + 1 }}</p>
                    <h3 class="text-[14px] leading-5">
                      {{ startUpperCase(hobby) }}
                    </h3>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div
            class="lg:hidden w-full grid grid-cols-2 gap-x-4 gap-y-[10px] pb-[30px] lg:pb-4"
          >
            <template v-for="attr in modelAttributes" :key="attr.value">
              <div
                class="flex justify-center items-center bg-[#0E132D] gap-[10px] py-[9px] px-[8px] rounded-[10px] border border-[#141A3D] border-opacity-70"
              >
                <span
                  class="flex justify-center items-center w-[34px] h-[34px] rounded-[10px]"
                  style="
                    background: rgb(10, 13, 30);
                    box-shadow:
                      rgb(30, 26, 61) 0px 0px 0px 1px,
                      rgb(19, 20, 46) 0px 0px 10px 2px inset;
                  "
                >
                  <img
                    class="w-[14px] h-auto"
                    :src="checkmark"
                    alt="checkmark"
                  />
                </span>
                <div class="flex-1 flex flex-col">
                  <p class="text-sm text-[#B1B5DB]">
                    {{ toStartCase(attr.label) }}
                  </p>
                  <h3 class="text-[14px] leading-5">
                    {{
                      Array.isArray(attr.value) && attr.value.length > 0
                        ? startUpperCase(attr.value[0])
                        : startUpperCase(attr.value)
                    }}
                    <template
                      v-if="attr.label == 'personality' && attr.value == ''"
                    >
                      Adaptive
                    </template>
                  </h3>
                </div>
              </div>
            </template>
            <template v-if="hobbies" v-for="(hobby, i) in hobbies" :key="hobby">
              <div
                class="flex justify-center items-center bg-[#0E132D] gap-[10px] py-[9px] px-[8px] rounded-[10px] border border-[#141A3D] border-opacity-70"
              >
                <span
                  class="flex justify-center items-center w-[34px] h-[34px] rounded-[10px]"
                  style="
                    background: radial-gradient(
                      255.14% 174.74% at 38.76% 155.71%,
                      #cc47ff 0%,
                      #9a5cff 100%
                    );
                  "
                >
                  <img
                    class="w-[14px] h-auto"
                    :src="checkmark"
                    alt="checkmark"
                  />
                </span>
                <div class="flex-1 flex flex-col">
                  <p class="text-sm text-[#B1B5DB]">Hobby {{ i + 1 }}</p>
                  <h3 class="text-[14px] leading-5">
                    {{ startUpperCase(hobby) }}
                  </h3>
                </div>
              </div>
            </template>
          </div>
        </div>
        <template v-if="modelRef.lore">
          <div
            class="pb-[60px] lg:pb-0 text-sm lg:text-base lg:mt-[30px] [&>p]:mb-[10px] leading-relaxed lg:leading-relaxed text-justify text-[#B1B5DB]"
            v-html="modelRef.lore"
          ></div>
        </template>
      </div>
    </div>
  </section>
</template>
